<template>
  <swiper-component
    v-intersection="onIntersect"
    :eager="eager"
    :effect="type"
    :hoverable="false"
    :id="id"
    keyboard
    navigation-btn-size="lg"
    :padding="padding"
    :slides="data"
    slides-type="HotspotHero"
    :slide-width="computedSlideWidth"
    @aura-response="(data) => onAuraResponse(data)"
    @slide-change="(slide) => onSlideChange(slide)"
  />
</template>

<script setup>
import { ref, computed, nextTick, onMounted } from "vue";
import { storeToRefs } from "pinia";
import { useQuasar } from "quasar";
import { useAuraStore } from "src/stores/aura";
import { urlFor } from "src/boot/sanityUrlFor";
import { useFullscreenSwiper } from "src/composables/swiper/useFullscreenSwiper";
import SwiperComponent from "src/components/swiper/SwiperComponent.vue";

defineOptions({ name: "HotspotSwiper" });

const props = defineProps({
  data: { type: Array, default: () => new Array(4).fill({}) },
  eager: { type: Boolean, default: false },
  id: { type: String, default: "defaultHostpotId" },
  padding: { type: Boolean, default: false },
  type: { type: String, default: "default" },
});

const $q = useQuasar();
const auraStore = useAuraStore();
const { aura } = storeToRefs(auraStore);
const { swiperPaddedWidth } = useFullscreenSwiper();
const isInView = ref(false);
const defaultSlideWidth = ref("100vw");
const isClient = ref(false);
const computedSlideWidth = computed(() => {
  if (!isClient.value) {
    return defaultSlideWidth.value;
  }
  return swiperPaddedWidth.value || `${window.innerWidth - 56}px`;
});

const onAuraResponse = (data) => {
  if (data.id !== props.id) return;
  onSlideChange(data.slide);
};

const onIntersect = (isIntersecting) => {
  isInView.value = isIntersecting;
};

const onSlideChange = (slide) => {
  if ($q.platform.is.mobile) return;
  if (!isInView.value) return;
  aura.value = {
    aura: urlFor(slide.image.asset.url).width(100).url(),
  };
};

onMounted(() => {
  isClient.value = true;
  nextTick(() => {
    defaultSlideWidth.value = swiperPaddedWidth.value;
  });
});
</script>
