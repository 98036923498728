<template>
  <div
    class="relative-position"
    ref="motionRef"
    :style="maxWidth ? `max-width: ${maxWidth};` : null"
  >
    <blur-mask v-if="mask" :mask="mask" />
    <div :class="textColor" class="q-py-sm relative-position">
      <portable-text :component="component" :value="value" />
    </div>
  </div>
</template>

<script setup>
import { computed, nextTick, onMounted, ref, watch } from "vue";
import { useMotion } from "@vueuse/motion";
import { PortableText } from "@portabletext/vue";
import { storeToRefs } from "pinia";
import { useColorStore } from "src/stores/color";
import { usePreferencesStore } from "src/stores/preferences";
import BlurMask from "src/components/blur/BlurMask.vue";

defineOptions({ name: "TextPortable" });

const props = defineProps({
  animated: { type: Boolean, default: false },
  component: { type: Object, require: false, default: () => ({}) },
  mask: { type: [String, Boolean], default: "x" },
  maxWidth: { type: [String, Boolean], default: false },
  value: { type: Array, require: true, default: () => [] },
});

const colorStore = useColorStore();
const { textColor } = storeToRefs(colorStore);
const preferencesStore = usePreferencesStore();
const { reducedMotion } = storeToRefs(preferencesStore);
const motionRef = ref(null);
const computedReducedMotion = computed(() => reducedMotion.value);
let motionInstance = null;

const createMotionInstance = () => {
  return useMotion(motionRef, {
    initial: {
      y: 100,
      opacity: 0,
    },
    enter: {
      y: 0,
      opacity: 1,
      transition: {
        type: "spring",
        stiffness: 350,
        damping: 20,
        delay: 50,
        onComplete: () => {
          motionInstance.apply("levitate");
        },
      },
    },
    levitate: {
      y: -5,
      transition: {
        duration: 2000,
        repeat: Infinity,
        ease: "easeInOut",
        repeatType: "mirror",
      },
    },
  });
};

const applyAnimations = () => {
  if (!computedReducedMotion.value && motionInstance && props.animated) {
    motionInstance.apply("enter");
  } else if (motionInstance) {
    motionInstance.stop();
  }
};

watch(computedReducedMotion, () => {
  if (motionInstance) {
    applyAnimations();
  } else if (!computedReducedMotion.value) {
    motionInstance = createMotionInstance();
    applyAnimations();
  }
});

onMounted(async () => {
  await nextTick();
  if (!computedReducedMotion.value) {
    motionInstance = createMotionInstance();
    applyAnimations();
  }
});
</script>
